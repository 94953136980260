import React, { memo, useEffect, useMemo } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import dayAPI from '~/utils/dayAPI'
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import {
  ResponsiveContainer,
  ReferenceLine,
  YAxis,
  XAxis,
  CartesianGrid,
  Area,
  Bar,
  Tooltip,
  ComposedChart,
  XAxisProps,
  YAxisProps,
} from 'recharts-new'
import type { Margin } from 'recharts-new/types/util/types'
import { useDatePick } from '~/modules/screener/useDatePick'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { getSymbolFromTo } from '~/modules/monitor/getSymbolFromToUtil'
import { getSymbolTicks } from '~/modules/monitor/getSymbolTicksUtil'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { fontWeight600 } from '~/css/font'
import { useSnapshot } from 'valtio'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import { useThemeOfParent } from '~/components/theme/useThemeOfParent'

const IntradayTrendChart = memo<
  React.PropsWithChildren<{
    symbol: Socket.SymbolString
    /** 是否為主站配色 */
    isAiTheme?: boolean
    /** 走勢填充區透明度 `0~1` */
    transparency?: number
    /** 隱藏 時間座標 */
    hideTimeLabel?: boolean
    /** 隱藏 價格座標 */
    hidePriceLabel?: boolean
    /** 時間座標間距 */
    tickGap?: number

    /** 隱藏 浮標工具 */
    hideTooltip?: boolean
    /** 時座標字體大小 */
    ticksSize?: number
    /** 時座標字體高度 */
    ticksHeight?: number
    /** 價格座標左側空白處 */
    priceTicksMargin?: number
    /** 價格座標字體大小 */
    priceTicksSize?: number
    /** 取交易期間，覆蓋 from to */
    intraday?: boolean
  }>
>(function IntradayTrendChart(props) {
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol])
  useEffect(() => {
    useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc')

    return () => {
      useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc')
    }
  }, [props.symbol])

  // 指定要get的交易時間 ----------------------------------------------

  /* 最近交易日 */
  const lastTradeString = useSnapshot(staticStore).tradedDate.day0
  const lastTradeDay = dayAPI(lastTradeString)
  /* 一般日期 */
  const baseDate =
    dayAPI().hour() < 9 || dayAPI().day() === 6 || dayAPI().day() === 0 ? lastTradeDay : dayAPI()

  const resultDate = baseDate

  /* 前一交易日收盤價 */
  const lastTradePrevRef = symbolInfo?.prevRef ?? 0

  /* 現貨交易時間 09:00 - 13:30 */
  const index_range = getSymbolFromTo(props.symbol, resultDate)

  /* 用於Recharts X軸座標標籤與間隔設定 */
  const tick_range = getSymbolTicks(props.symbol, resultDate)

  /* 拿商品資料 */
  const { data: rawData } = useSymbolHistoryResource({
    symbol: props.symbol,
    fromTo: index_range,
    resolution: '1',
    intraday: props.intraday,
  })

  /** 主題 */
  const theme = useThemeOfParent().palette.mode
  const themeColor = theme === 'dark'

  /* 共用 props 宣告 start */
  const categoryChartMargin: Margin = {
    top: 8,
    right: 8,
    left: props.priceTicksMargin ?? 10,
    bottom: 0,
  }
  const xAxisProps: XAxisProps = {
    type: 'number',
    dataKey: 'unixtime',
    domain: index_range,
    tickMargin: 0,
    interval: props.tickGap ?? 0,
    ticks: tick_range,
    hide: props.hideTimeLabel,
    height: props.ticksHeight,
    tick: { fontSize: props.ticksSize ?? 14, fill: themeColor ? '#cccccc' : '#666666' },
    tickFormatter: timeStr => new Date(timeStr * 1000).getHours().toString(),
  }

  const volumeYAxisProps: YAxisProps = {
    dataKey: 'volume',
    hide: true,
    yAxisId: 2,
  }

  const closeYAxisProps: YAxisProps = {
    dataKey: 'close',
    hide: props.hidePriceLabel,
    yAxisId: 1,
    tick: {
      fontSize: props.priceTicksSize ?? 14,
      fill: themeColor ? '#cccccc' : '#666666',
    },
    tickMargin: 0,
    tickFormatter: value => (value + lastTradePrevRef).toFixed(symbol_decimal_Places),
  }
  /* 共用 props 宣告 end */

  /** 圖表資料區間 */
  const data = useMemo(() => {
    return (
      lastTradePrevRef &&
      rawData?.map(datum => {
        return {
          /** Recharts x 軸時間資料 */
          unixtime: dayAPI(datum.time).unix(),
          /** Recharts 圖表座標 */
          datetime: dayAPI(datum.time).format('HH:mm'),
          /** 原始收盤價 */
          originalClose: datum.close,
          /** Recharts y 軸價格資料 收盤價 - 昨收 (參考價) */
          close: datum.close - lastTradePrevRef,
          /** 成交量 */
          volume: datum.volume,
        }
      })
    )
  }, [lastTradePrevRef, rawData])

  /** 無資料時保持圖表為空白 */
  if (!data || (data && data.length === 0))
    return (
      <ResponsiveContainer
        width='100%'
        height='100%'
      >
        <ComposedChart
          data={[0]}
          margin={categoryChartMargin}
        >
          <YAxis {...closeYAxisProps} />
          <YAxis {...volumeYAxisProps} />
          <XAxis {...xAxisProps} />
        </ComposedChart>
      </ResponsiveContainer>
    )

  //圖表 y 籌上下區間指定範圍 *包留了漲停與跌停的些空間-------------------------
  const minValue = Math.min(...(data?.map(datum => datum.close) || []))
  const maxValue = Math.max(...(data?.map(datum => datum.close) || []))
  const midValue = 0

  //價格座標區間
  const defaultValue =
    Math.abs(maxValue) >= Math.abs(minValue) ? Math.abs(maxValue) * 1.1 : Math.abs(minValue) * 1.1
  const compareValue = defaultValue
  const price_range: [number, number] = [Number(-compareValue), Number(compareValue)]

  //價格座標文字位置對齊
  const defaultTick =
    Math.abs(maxValue) >= Math.abs(minValue) ? Math.abs(maxValue) * 1 : Math.abs(minValue) * 1
  const compareTick = defaultTick
  const price_tick: number[] = [
    Number(-compareTick),
    Number(-compareTick / 2),
    Number(midValue),
    Number(compareTick / 2),
    Number(compareTick),
  ]

  /** 處理商品小數點 2 位 */
  const price_decimal_Places = lastTradePrevRef >= 50 ? 1 : lastTradePrevRef >= 500 ? 0 : 2
  const symbol_decimal_Places = props.symbol.includes('T') ? 0 : price_decimal_Places

  const dataMax = Math.max(...data.map(i => i.close))
  const dataMin = Math.min(...data.map(i => i.close))

  /** 以下 ID 是處理 重複使用圖表顏色無法指定問題 */
  const splitColorID = ('splitColor' + props.symbol).toString()
  const strokeColorID = ('strokeColor' + props.symbol).toString()
  const fillID = 'url(#' + splitColorID + ')'
  const strokeID = 'url(#' + strokeColorID + ')'
  const opacity = props.transparency ?? 0.4
  const strokeColor = themeColor ? '#333333' : '#efefef'
  const versionColor = props.isAiTheme ? (themeColor ? '#111824' : '#202D42') : '#222222'
  const fillColor = themeColor ? versionColor : '#ffffff'

  /** 圖表 Area 上色 */
  const priceChangOffset = () => {
    if (dataMax <= 0) return 0
    if (dataMin >= 0) return 1
    return dataMax / (dataMax - dataMin)
  }
  const priceChangeOff = priceChangOffset()

  /** 成交量 */
  const volumeValue = Math.max(...(data?.map(datum => datum.volume) || []))
  const volume_range: [number, number] = [0, volumeValue * 3]

  const CustomizedTooltip = ({ payload }: { payload?: any[] }) => {
    if (!payload || (payload && payload.length < 1)) return null
    const datum = payload[0].payload
    const change = lastTradePrevRef && datum.originalClose - lastTradePrevRef
    const changePct = lastTradePrevRef && change && (change / lastTradePrevRef) * 100
    const arrowSymbol = change === 0 ? '' : change > 0 ? '▲' : '▼'
    const changeColor =
      change === 0 ? (themeColor ? '#ffff22' : '#555555') : change > 0 ? '#ee2222' : '#11cc11'

    const ItemText = styled.div`
      margin-left: 8px;
      color: ${props_ => props_.color};
      ${fontWeight600}
    `

    return (
      <div
        css={css`
          padding: 0px 5px 0px 5px;
          background-color: ${themeColor ? '#222222' : '#eeeeee'};
          color: ${themeColor ? '#eeeeee' : '#222222'};
          opacity: 0.8;
          border: 1px solid #b4b4b4;
          border-radius: 5px;
          font-size: 14px;
          line-height: 20px;
        `}
      >
        <div css={flex.h.default}>
          時間
          <ItemText>{datum.datetime}</ItemText>
        </div>
        <div css={flex.h.default}>
          價格<ItemText>{datum.originalClose}</ItemText>
        </div>
        <div css={flex.h.default}>
          漲跌
          <ItemText color={changeColor}>{arrowSymbol + change?.toFixed(2)}</ItemText>
        </div>
        <div css={flex.h.default}>
          漲幅
          <ItemText color={changeColor}>{arrowSymbol + changePct?.toFixed(2) + '%'}</ItemText>
        </div>
        <div css={flex.h.default}>
          成交量
          <ItemText color={themeColor ? '#ffff00' : '#ff9900'}>{datum.volume}</ItemText>
        </div>
      </div>
    )
  }

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <ComposedChart
        data={data}
        margin={categoryChartMargin}
      >
        <YAxis
          {...closeYAxisProps}
          domain={price_range}
          ticks={price_tick}
        />
        <YAxis
          {...volumeYAxisProps}
          domain={volume_range}
        />

        <XAxis {...xAxisProps} />

        <CartesianGrid
          strokeDasharray='1'
          stroke={strokeColor}
          fill={fillColor}
          verticalPoints={[]}
          horizontalPoints={[0]}
        />
        <defs>
          <linearGradient
            id={splitColorID}
            x1='0'
            y1='0'
            x2='0'
            y2='1'
          >
            <stop
              offset={priceChangeOff / 1.75}
              stopColor={themeColor ? '#ff2222' : '#ff2222'}
              stopOpacity={0.8}
            />
            <stop
              offset={priceChangeOff}
              stopColor={themeColor ? '#551111' : '#ff2222'}
              stopOpacity={0.8}
            />
            <stop
              offset={priceChangeOff}
              stopColor={themeColor ? '#115511' : '#00cc00'}
              stopOpacity={0.8}
            />
            <stop
              offset={priceChangeOff / 0.25}
              stopColor={themeColor ? '#11ff11' : '#009900'}
              stopOpacity={0.8}
            />
          </linearGradient>
          <linearGradient
            id={strokeColorID}
            x1='0'
            y1='0'
            x2='0'
            y2='1'
          >
            <stop
              offset={priceChangeOff}
              stopColor={themeColor ? '#ff2222' : '#ff2222'}
              stopOpacity={0.8}
            />
            <stop
              offset={priceChangeOff}
              stopColor={themeColor ? '#11ff11' : '#00aa00'}
              stopOpacity={0.8}
            />
          </linearGradient>
        </defs>
        <Area
          yAxisId={1}
          type='linear'
          dataKey='close'
          dot={false}
          stroke={strokeID}
          fill={fillID}
          fillOpacity={opacity}
          isAnimationActive={false}
        />
        <Bar
          yAxisId={2}
          dataKey='volume'
          barSize={20}
          fill={themeColor ? '#3F51B5' : '#2763D6'}
          isAnimationActive={false}
        />
        <ReferenceLine
          yAxisId={1}
          isFront={true}
          y={0}
          stroke={'#2196F3'}
          strokeWidth={0.5}
        />
        {!props.hideTooltip && <Tooltip content={<CustomizedTooltip />} />}
      </ComposedChart>
    </ResponsiveContainer>
  )
})

export default IntradayTrendChart
